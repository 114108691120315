import { useForm } from '@/use/Base/useForm'
import { FormHandler } from '@/utils/use/formHandler'
import { inject } from 'vue'
import { rulesPositionCreate } from '@/use/Form/Lot/Position/rules.PositionCreate'

export function usePositionCreate (formData, { scrollErrorHandler }) {
  const { rules, setValidationPrice } = rulesPositionCreate(formData, { scrollErrorHandler })
  const observer = inject('observer')

  function reCalculation () {
    observer.broadcast(formData.price)
  }

  const formAction = async () => {
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors,
    reCalculation,
    setValidationPrice
  }
}

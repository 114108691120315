import { reactive } from 'vue'
import { useStore } from 'vuex'
import { checkMoreThenZero, checkNotLessThenZero } from '@/utils/validator/customValidator'
import { RulesValidationScroll } from '@/utils/validator/RulesValidationScroll'
import { useValidateField } from '@/use/Form/useValidateField'

export function rulesPositionCreate (formData, { scrollErrorHandler }) {
  const store = useStore()
  const lot = store.getters['lot/getLot']

  const { withScrollTo, getPriceErrorMsg, priceValidator, requiredWithScrollTo, paramsScroll } = useValidateField(formData, scrollErrorHandler, lot)

  const equalCriterionValidator = () => {
    const sum = +formData.criterion_non_value + +formData.criterion_skill + +formData.criterion_value
    return sum === 1
  }
  const createEqualCriterionValid = (field) => ({
    equalCriterion: withScrollTo(field, 'Сумма групп критериев должна быть равна 1', equalCriterionValidator)
  })

  const rules = reactive({
    name: { ...requiredWithScrollTo('name') },
    characteristic: {
      ...requiredWithScrollTo('characteristic'),
      ...RulesValidationScroll.createMaxLengthValid(
        'Характеристики',
        300,
        {
          ...paramsScroll,
          field: 'characteristic'
        })
    },
    measurement_id: { ...requiredWithScrollTo('measurement_id') },
    price: {
      priceController: withScrollTo('price', () => getPriceErrorMsg.value, priceValidator)
    },
    quantity: {
      ...requiredWithScrollTo('quantity'),
      minLength: withScrollTo('quantity', 'Количество не может быть меньше 0', checkNotLessThenZero),
    },
    criterion_value: {
      ...requiredWithScrollTo('criterion_value'),
      minLength: withScrollTo('criterion_value', 'Критерий не может быть 0', checkMoreThenZero),
      ...createEqualCriterionValid('criterion_value')
    },
    criterion_non_value: createEqualCriterionValid('criterion_non_value'),
    criterion_skill: createEqualCriterionValid('criterion_skill')
  })

  return {
    rules
  }
}

<script setup>
import VLotCreate from '@/components/views/Lot/VLotCreate'
import SpinnerPage from '@/components/views/Spinner/SpinnerPage'
import { useRoute } from 'vue-router'
import { computed, watch, ref } from 'vue'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()

const isLoading = ref(false)
function toggleIsLoading () {
  isLoading.value = !isLoading.value
}

const currentPattern = computed(() => {
  return store.getters['lot/getCurrentPattern'](+route.params.id)
})

watch(currentPattern, () => {
  toggleIsLoading()
  setTimeout(() => toggleIsLoading(), 100)
})

</script>

<template>
  <SpinnerPage v-if="isLoading" />
  <VLotCreate
    v-if="currentPattern && !isLoading"
    :pattern="currentPattern.pattern"
    :pattern-id="currentPattern.id"
  />
</template>

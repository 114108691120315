<template>
  <ul class="accordion" v-if="positions.length">
    <li
      class="accordion__list-item"
      v-for="(item, i) in positions"
      :key="item.id"
      :id="`lot-position--${item.id}`"
    >
      <VLotPositionItem
        :form-data="item"
        :is-bidder="isBidder"
        :count="i + 1"
        class-title="accordion__count-primary"
        :scroll-error-handler="scrollToPositionInvalid"
        @delete="deletePosition(item)"
        :is-open="openAccordion"
        @toggleOpen="handlerOpenAccordion"
        @copyPosition="copyLotPositionHandler(item)"
      />
    </li>
  </ul>

  <VButton
    v-if="!isBidder"
    type="button"
    mod="plus"
    class="btn-primary-width-100 btn-width-100 mb-60"
    @click="createPosition"
  >
    Добавить позицию
  </VButton>
</template>

<script>
import { reactive, ref } from 'vue'
import VLotPositionItem from '@/components/list/PositionList/VLotPositionItem'
import VButton from '@/components/ui/buttons/VButton'
import { DefaulterNomenclatur } from '@/modules/defaulter/DefaulterNomenclatur'
import { scrollToChildById, scrollToNode, scrollToNodeById } from '@/utils/scroll'
import { createIdRandom } from '@/utils/create'

export default {
  emits: ['delete'],
  props: {
    positions: {
      type: Array,
      required: true
    },
    isBidder: {
      type: Boolean,
      required: false,
      default: false
    },
    lotCriterion: {
      type: Array,
      required: false
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(true)
    const openAccordion = ref(null)

    const handlerOpenAccordion = (id) => {
      if (openAccordion.value === id) {
        openAccordion.value = null
      } else {
        openAccordion.value = id
      }
    }

    function createPositionMainParams () {
      return {
        name: '',
        price: null,
        quantity: null,
        measurement: null,
        measurement_id: null,
        characteristic: ''
      }
    }

    const createPosition = () => {
      const formData = reactive({
        ...createIdRandom(),
        ...createPositionMainParams(),
        price_coef: 1,
        price_ball: 2,
        is_quantity_coef: false,
        quantity_coef: null,
        quantity_ball: null,
        parameters: reactive([
          DefaulterNomenclatur.createPrice(),
          ...props.lotCriterion
        ]),
        criterion_value: '1',
        criterion_non_value: '0',
        criterion_skill: '0',
        participant_note: ''
      })
      props.positions.push(formData)
    }

    const deletePosition = (item) => {
      emit('delete', item.id)
    }

    function copyLotPositionHandler (item) {
      const copyItem = reactive({
        ...item,
        parameters: reactive([...item.parameters]),
        ...createIdRandom(),
        ...createPositionMainParams()
      })

      props.positions.push(copyItem)

      handlerOpenAccordion(item.id)

      setTimeout(() => scrollToNodeById('lot-position--' + copyItem.id), 400)
      setTimeout(() => handlerOpenAccordion(copyItem.id), 700)
    }

    function scrollToPositionInvalid ({ id, field }) {
      const positionNode = document.getElementById('lot-position--' + id)
      const fieldId = `lot-position__${field}--${id}`
      scrollToNode(positionNode)

      if (openAccordion.value !== id) {
        handlerOpenAccordion(id)
      }

      setTimeout(() => scrollToChildById(positionNode, fieldId), 350)
    }

    return {
      openAccordion,
      deletePosition,
      createPosition,
      isLoading,
      handlerOpenAccordion,
      copyLotPositionHandler,
      scrollToPositionInvalid
    }
  },
  components: {
    VLotPositionItem,
    VButton
  }
}
</script>

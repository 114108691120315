<template>
  <div class="block__subtitle mb-40" v-if="!isBidder">Лот</div>

  <div
    v-if="!isBidder"
  >
    <div class="form__subtitle mb-40">Параметры лота</div>
    <VLotParametersMain
      :is-bidder="isBidder"
    />
  </div>

  <div v-if="!isBidder || isEditDelivery">
    <div class="form__subtitle mb-40">Условия поставки</div>
    <VLotParametersDeliveryConditions
      :is-bidder="isBidder"
      :is-edit="isEditDelivery"
    />
  </div>

  <div v-if="!isBidder || isEditPayment">
    <div class="form__subtitle mb-40">Условия оплаты</div>
    <VLotParametersPaymentConditions
      :is-bidder="isBidder"
      :minCriterionDisabled="minCriterionDisabled"
      :maxCriterionDisabled="maxCriterionDisabled"
      :is-edit="isEditPayment"
    />
  </div>

  <VTable
    v-if="isBidder && rows.length"
    :headRow="headBidder"
    :rows="rows"

    :is-min-height="false"
    :is-checkbox="false"
    class-head="border"
  />

  <div v-if="!isBidder">
    <div class="form__subtitle mb-40">Сопроводительная документация</div>
    <VLotParametersFile
      :is-bidder="isBidder"
    />
  </div>

</template>

<script setup>
import { defineProps, provide } from 'vue'

import VLotParametersDeliveryConditions from '@/components/views/Lot/Create/Part/VLotParametersDeliveryConditions'
import VLotParametersPaymentConditions from '@/components/views/Lot/Create/Part/VLotParametersPaymentConditions'
import VLotParametersFile from '@/components/views/Lot/Create/Part/VLotParametersFile'
import VLotParametersMain from '@/components/views/Lot/Create/Part/VLotParametersMain'

import VTable from '@/components/ui/table/VTable'

const props = defineProps({
  formData: {
    type: Object,
    required: true
  },
  v$: {
    type: Object,
    required: true
  },
  validateField: {
    type: Function,
    required: true
  },
  serverErrors: {
    type: [Object, Array],
    required: false
  },
  isBidder: {
    type: Boolean,
    required: false
  },
  isEditPayment: {
    type: Boolean,
    required: false
  },
  isEditDelivery: {
    type: Boolean,
    required: false
  },
  headBidder: {
    type: Array,
    required: false
  },
  rows: {
    type: Array,
    required: false
  },
  maxCriterionDisabled: {
    type: Boolean,
    required: true
  },
  minCriterionDisabled: {
    type: Boolean,
    required: true
  }
})

provide('formData', props.formData)
provide('v$', props.v$)
provide('validateField', props.validateField)
provide('serverErrors', props.serverErrors)

</script>

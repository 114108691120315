<script setup>
import { computed, defineProps } from 'vue'
import VBidderTemplateParamterItem from '@/components/views/Lot/Parameter/Part/VBidderTemplateParamterItem'

const props = defineProps({
  formData: {
    type: Object,
    required: true
  },
  nameKey: {
    type: String,
    required: false,
    default: 'name'
  },
  isYellow: {
    type: Boolean,
    required: false
  }
})

const getParameters = computed(() => {
  return props.formData
})

</script>

<template>
  <div
    v-for="(el, idx) in getParameters"
    :id="idx"
    :key="el[nameKey]"
    :class="['elements elements--accordion elements--parameter', el.state]"
  >
   <VBidderTemplateParamterItem
    :item="el"
   />
  </div>
</template>

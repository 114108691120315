// type = type, default: defaultValue, equal = candidate[key], typeItem = for Array
export const interfaceOptions = {
  id: { type: 'string', default: String(Math.random()) },
  name: { type: 'string', default: '' },
  balls: { type: 'number', default: 1 }
}

export const interfaceParameters = {
  id: { type: 'string', default: String(Math.random()) },
  external_id: { type: 'number', default: null },
  action: { type: 'string', default: '' },
  state: { type: 'string', default: '' },
  full_name: { type: 'string', default: '', equal: 'name' },
  short_name: { type: 'string', default: '', equal: 'name' },
  is_criterion: { type: 'boolean', default: false },
  department_id: { type: 'number', default: 1 },
  options: { type: 'array', default: [], typeItem: interfaceOptions },
  isEdit: { type: 'boolean', default: false },
  group: { type: 'number', default: null },
  coef: { type: 'number', default: 1 },
  is_selected: { type: 'boolean', default: null }
}

export const interfacePosition = {
  external_id: { type: 'number', default: null },
  id: { type: 'number', default: Math.random() },
  name: { type: 'string', default: '' },
  measurement: { type: 'string', default: '' },
  characteristic: { type: 'string', default: '' },
  price: { type: 'number', default: null },
  price_coef: { type: 'number', default: 1 },
  price_ball: { type: 'number', default: 2 },
  quantity: { type: 'number', default: 1 },
  is_quantity_coef: { type: 'boolean', default: false },
  quantity_coef: { type: 'number', default: null },
  quantity_ball: { type: 'number', default: null },
  parameters: { type: 'array', default: [], typeItem: interfaceParameters },
  criterion_value: { type: 'string', default: '1' },
  criterion_non_value: { type: 'string', default: '0' },
  criterion_skill: { type: 'string', default: '0' }
}

<script setup>
import VIcon from '@/components/ui/icon/VIcon'
import { computed, defineProps } from 'vue'

const props = defineProps({
  formData: {
    type: Object,
    required: true
  },
  changeParameter: {
    type: Function,
    required: true
  },
  deleteParameter: {
    type: Function,
    required: true
  },
  nameKey: {
    type: String,
    required: false,
    default: 'name'
  },
  isYellow: {
    type: Boolean,
    required: false
  }
})

const getParameters = computed(() => {
  return props.formData
})

</script>

<template>
  <div
    v-for="(el, idx) in getParameters"
    :id="idx"
    :key="el[nameKey]"
    :class="['elements elements--accordion elements--parameter', el.state]"
  >
    <p class="elements__description">
      {{ el[nameKey] }}
    </p>
    <VIcon
      class="elements__icon"
      width="38"
      height="38"
      :icon-name="isYellow ? 'YellowEdit' : 'AccordionEdit'"
      @click="changeParameter(el)"
    />

    <VIcon
      class="elements__icon"
      width="38"
      height="38"
      :icon-name="isYellow ? 'YellowDelete' : 'AccordionDelete'"
      @click="deleteParameter(el)"
    />
  </div>
</template>

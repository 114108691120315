<template>
  <div class="form__subtitle mb-40">Основные параметры</div>
  <div class="form__flex form__flex-start">
    <div class="form__left">
      <VDatePicker
        name="started_at"
        id="started_at"
        v-model="formData.started_at"
        :errors="v$.started_at.$errors"
        :server-errors="serverErrors.value?.started_at"
        @blur="validateField"
        :max="formData.duration"
        @input="validateField"
        class-form="mb-20"
        placeholder="Дата начала и время старта"
      />

      <VDatePicker
        name="duration"
        id="duration"
        v-model="formData.duration"
        :errors="v$.duration.$errors"
        :server-errors="serverErrors.value?.duration"
        @blur="validateField"
        :min="formData.started_at"
        :min-add="10"
        @input="validateField"
        class-form="mb-20"
        placeholder="Дата окончания торгов"
      />

      <VSelect
        name="categories"
        id="categories"
        v-model="formData.categories"
        :errors="v$.categories.$errors"
        :server-errors="serverErrors.value?.categories"
        @blur="validateField"
        @input="validateField"
        @remove="removeCategory"
        :close-on-select="false"
        :options="categoryOptions"
        multiple
        key-record="id"
        track-by="id"
        classes="select-border-bottom"
        class="input-long"
        placeholder="Категории ТП"
      />
    </div>

    <div class="form__right">
      <VMask
        type="extension_time"
        regex="00:00"
        mask-custom="00:00"
        :mask-type="true"
        id="extension_time"
        is-range
        :range="range"
        :is-masked-value="true"
        name="extension_time"
        :class-input="['input input-long input-placeholder-black']"
        v-model="formData.extension_time"
        :errors="v$.extension_time.$errors"
        :server-errors="serverErrors.value?.extension_time"
        @blur="validateField"
        @input="validateField"
        placeholder="Время автоматического продления ТП"
      />

      <VMask
        type="step_time"
        regex="00:00"
        mask-custom="00:00"
        :mask-type="true"
        id="step_time"
        is-range
        :range="range"
        :is-masked-value="true"
        name="step_time"
        :class-input="['input input-long input-placeholder-black']"
        v-model="formData.step_time"
        :errors="v$.step_time.$errors"
        :server-errors="serverErrors.value?.step_time"
        @blur="validateField"
        @input="validateField"
        placeholder="Порог времени лучшего предложения"
      />
    </div>
  </div>

  <div class="form__subtitle mb-40" v-if="isPriceReduction">
    Параметры ТП с понижением цены
  </div>
  <div class="form__flex form__flex-start">
    <div v-if="isPriceReduction" class="form__left">
      <VInput
        name="min_step"
        id="min_step"
        type="number"
        v-model="formData.min_step"
        :max="99"
        :min="0"
        mode="diapason"
        :errors="v$.min_step.$errors"
        :server-errors="serverErrors.value?.min_step"
        @blur="validateField"
        @input="validateField"
        classes="select-border-bottom"
        class="input-long"
        placeholder="Минимальная величина шага"
      />

      <VCheckbox
        id="is_procent"
        name="is_procent"
        v-model="formData.is_procent"
        :server-errors="serverErrors.value?.is_procent"
        class-form="form-checkbox form-checkbox--lot mb-40"
        :is-switch="true"
        class-label="bg-white"
        @click="
          () => {
            formData.min_step = null
            formData.max_step = null
          }
        "
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Указать в процентах
          </label>
        </template>
      </VCheckbox>
    </div>

    <div class="form__right" v-if="isPriceReduction">
      <VInput
        name="max_step"
        id="max_step"
        type="number"
        :max="100"
        :min="0"
        mode="diapason"
        v-model="formData.max_step"
        :errors="v$.max_step.$errors"
        :server-errors="serverErrors.value?.max_step"
        @blur="validateField"
        @input="validateField"
        classes="select-border-bottom"
        class="input-long"
        placeholder="Максимальная величина шага"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, reactive } from 'vue'
import VDatePicker from '@/components/ui/form/VDatePicker'
import VInput from '@/components/ui/form/VInput'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import VMask from '@/components/ui/form/VMask'
import VSelect from '@/components/ui/form/VSelect'
import { manualMemoization } from '@/utils/memoization/manualMemoization'
import { CATEGORY_LOT } from '@/utils/manual/manualList'

  const props = defineProps({
    formData: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
    },
    validateField: {
      type: Function,
      required: true,
    },
    serverErrors: {
      type: [Object, Array],
      required: false,
    },
  })

  const range = {
    hours: {
      min: 0,
      max: 23,
      pos: [0, 1],
    },
    min: {
      min: 0,
      max: 59,
      pos: [3, 4],
    },
  }

  const isPriceReduction = computed(() => {
    return props.formData.procedure_type_id === 2
  })

  const category = reactive([])

  const categoryOptions = computed(() => {
    let categoryArr = []
    if (props.formData.procedure_type_id !== 2 && props.formData.procedure_type_id !== 3) {
        categoryArr = category.filter(item => item.is_construction !== true)
    } else categoryArr = category
    return categoryArr
})

  const removeCategory = (el) => {
    props.formData.categories = props.formData.categories.filter(cat => cat.id !== el.id)
  }

  onMounted(async() => {
    category.length = 0
    category.push(...await manualMemoization(true, CATEGORY_LOT))
  })
</script>

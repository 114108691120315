import { inject } from 'vue'
import { useStore } from 'vuex'
import { LOT_CONTROLLER } from '@/utils/consts'

export function useSetPattern () {
  const store = useStore()
  const lotController = inject(LOT_CONTROLLER)
  const getPatterns = async () => {
    const data = await lotController.getTemplate()
    data.forEach(item => {
      item.name = item.pattern.name
    })
    store.commit('lot/setPattern', data)
  }

  return { getPatterns }
}

<template>
  <form class="form form__accordion">
    <div class="form__inner">
      <VLotPositionCreateFormMain
        :form-data="formData"
        :v$="v$"
        :server-errors="serverErrors"
        :validate-field="validateField"
        :re-calc="reCalc"
        :is-bidder="isBidder"
      />

      <VGroupCriterion
        v-if="!isBidder"
        :form-data="formData"
        :v$="v$"
        :server-errors="serverErrors"
        :validate-field="validateField"
        :isBidder="isBidder"
        @copyPosition="$emit('copyPosition')"
      />

      <div class="form__subtitle mb-40" v-if="getVisibleParameters.length">Доп. параметры</div>

      <div class="elements__list" v-if="getVisibleParameters.length">
        <div
          class="width-100"
          v-if="!isBidder"
        >
          <VTemplateParameter
            :delete-parameter="deleteParameter"
            :change-parameter="changeParameter"
            :form-data="getVisibleParameters"
            nameKey="full_name"
            :is-yellow="false"
          />
        </div>

        <div
          v-else
          class="width-100"
        >
          <VBidderTemplateParameter
            :form-data="getVisibleParameters"
            nameKey="full_name"
            :is-yellow="false"
          />
        </div>

      </div>

      <VButton
        v-if="!isBidder"
        mod="plus"
        class="btn-long mb-40"
        type="button"
        @click="openVModal"
      >
        Добавить параметр
      </VButton>
    </div>

    <VTable
      v-if="rows.length"
      :head-row="isBidder ? headBidder : head"
      :rows="rows"

      :is-pagination="false"
      :is-min-height="false"
      :is-checkbox="false"
      class-head="border"
    />

    <teleport to="body">
      <VModal
        v-if="showVModal"
        modal-inner-class="width-885"
        @close="closeModalAndChangeIsEdit"
      >
        <VLotPositionCreateParametersForm
          :parameters="formData.parameters"
          :selectedParameter="selectedParameter"
          :close-modal="closeModalAndChangeIsEdit"
          @add="addRows"
          @change="changeRows"
          @clear="clear"
        />
      </VModal>
    </teleport>
  </form>
</template>

<script>
import { computed, reactive } from 'vue'
import VButton from '@/components/ui/buttons/VButton'
import VModal from '@/components/ui/modal/VModal'
import useVModal from '@/use/Base/useVModal'
import VLotPositionCreateParametersForm from '@/components/views/Lot/Parameter/VLotPositionCreateParametersForm'
import VLotPositionCreateFormMain from '@/components/views/Lot/Position/Part/VLotPositionCreateFormMain'
import VTemplateParameter from '@/components/views/Lot/Parameter/Part/VTemplateParameter'
import VBidderTemplateParameter from '@/components/views/Lot/Parameter/Part/VBidderTemplateParameter'
import VGroupCriterion from '@/components/views/Lot/VGroupCriterion'
import { useStore } from 'vuex'
import VTable from '@/components/ui/table/VTable'
import { usePositionTable } from '@/use/Form/Lot/Position/usePostitionTable'
export default {
  emits: ['copyPosition'],
  props: {
    formData: {
      type: Object,
      required: true
    },
    v$: {
      type: Object,
      required: true
    },
    serverErrors: {
      type: [Array, Object],
      required: true
    },
    validateField: {
      type: Function,
      required: true
    },
    isBidder: {
      type: Boolean,
      required: false
    },
    reCalc: {
      type: Function,
      required: false
    },
    toggleItemQuantity: {
      type: Function,
      required: false
    }
  },
  setup (props) {
    const { openVModal, closeVModal, showVModal } = useVModal()
    const selectedParameter = reactive({})
    const store = useStore()

    const closeModalAndChangeIsEdit = () => {
      store.commit('parameter/setIsEdit', false)
      closeVModal()
    }

    const changeParameter = (el) => {
      Object.keys(el).forEach(key => {
        selectedParameter[key] = el[key]
      })
      selectedParameter.action = 'change'
      openVModal()
    }

    const getVisibleParameters = computed(() => {
      return props.formData.parameters.filter(p => !p?.hide && !p?.is_characteristic)
    })

    const clear = () => {
      Object.keys(selectedParameter).forEach(key => { selectedParameter[key] = null })
    }

    const {
      headBidder,
      head,
      rows,
      addRows,
      changeRows,
      deleteParameter
    } = usePositionTable(props.formData)

    return {
      headBidder,
      getVisibleParameters,
      changeRows,
      clear,
      head,
      rows,
      addRows,
      openVModal,
      closeVModal,
      showVModal,
      deleteParameter,
      changeParameter,
      selectedParameter,
      closeModalAndChangeIsEdit
    }
  },
  components: {
    VTable,
    VGroupCriterion,
    VTemplateParameter,
    VLotPositionCreateFormMain,
    VLotPositionCreateParametersForm,
    VBidderTemplateParameter,
    VButton,
    VModal
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.custom-flex {
  color: $color-red;
  font-size: $font-size-primary;
  width: 100%;
  text-align: left;
}
</style>

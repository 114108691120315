<template>
  <div>
    <input type="radio" :value="value.id" :id="value.name" class="radio" :disabled="disabled" @click="selectElement" :checked="checkedElement === value.id">
    <label :for="value.name" :class="['label label-checkbox', classRadio]">{{ value.name }}</label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    checkedElement: {
      type: Number
    },
    disabledElement: {
      type: [Array, String]
    },
    defaultElement: {
      type: String
    },
    classRadio: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['selectElement'],
  setup (props, { emit }) {
    const selectElement = () => {
      emit('selectElement', props.value.id)
    }

    return {
      selectElement
    }
  }
}
</script>

<style scoped>
.label-checkbox {
  cursor: pointer;
}
</style>

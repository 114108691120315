<template>
    <h4 class="modal__title mb-40">Доп. параметр</h4>
    <form @submit.prevent="onSubmit" class="form width-100">

      <VSelect
        name="department_id"
        id="department_id"
        v-model="formData.department_id"
        :errors="v$.department_id.$errors"
        :options="department"
        @input="validateField"
        @blur="validateField"
        placeholder="Департамент"
      />

        <VSearch
          :class-form="['transform no-close', formData.department_id ? 'show' : '', 'max-width-630']"
          name="full_name"
          :class-search="false"
          placeholder="Найти параметр"
          class-input="input-border-all input-padding-25 input--bg-white input-height-48 input-search"
          v-model="formData.full_name"
          :errors="v$.full_name.$errors"
          :is-edit="formData.isEdit"
          is-variables
          variables-key="full_name"
          :variables="parameters"
          @selected="changeSelected"
          @add="setSelected"
        />

      <VSelect
        :class-form="['transform no-close', formData.department_id && isSelected && !v$.full_name.$error ? 'show' : '', 'max-width-630']"
        v-model="formData.options"
        name="options"
        id="options"
        multiple
        :errors="v$.options.$errors"
        :options="values"
        @remove="removeOptions"
        :close-on-select="false"
        @blur="validateField"
        @input="validateField"
        classes="select-border-bottom"
        placeholder="Значение"
      />

      <VCheckbox
        :class-form="['form-checkbox ml-20 mb-20 transform no-close', formData.department_id && isSelected && !v$.full_name.$error && formData.options.length ? 'show-check' : '', 'max-width-630']"
        id="is_criterion"
        name="is_criterion"
        class-label="label"
        :value="formData.is_criterion"
        v-model="formData.is_criterion"
        :errors="v$.is_criterion.$errors"
        @blur="validateField"
        @input="validateField"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Учитывать как критерий
          </label>
        </template>
      </VCheckbox>

      <div class="form__buttons">
        <button type="submit" class="btn btn-primary btn-bg-green">Сохранить</button>
        <button type="button" class="btn btn-primary btn-bg-transparent" @click="closeModal">Отмена</button>
      </div>
    </form>
</template>

<script setup>
import { onMounted, defineEmits, reactive, ref, defineProps, watch } from 'vue'

import VSelect from '@/components/ui/form/VSelect'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import { useParametersCreate } from '@/use/Form/Lot/Parameter/useParametersCreate'
import VSearch from '@/components/ui/form/Search/VSearch'
import { manualMemoization } from '@/utils/memoization/manualMemoization'
import { useVariablesSearch } from '@/use/useVariablesSearch'
import { useStore } from 'vuex'
import { PARAMETER_DEPARTMENT } from '@/utils/manual/manualList'

const emit = defineEmits(['add', 'clear', 'change'])

const props = defineProps({
  parameters: {
    type: Array,
    required: true
  },
  closeModal: {
    type: Function,
    required: true
  },
  selectedParameter: {
    type: Object,
    required: false
  }
})

const department = reactive([])
const parameters = reactive([])
const values = reactive([])
const selectedSearch = reactive({})
const withoutCurrent = reactive([])
const store = useStore()

const isSelected = ref(false)

const getDepartment = async () => {
  const response = await manualMemoization(true, PARAMETER_DEPARTMENT)
  if (response.length) {
    department.push(...response)
  }
}

const changeSelected = (val) => {
  isSelected.value = val
}

const removeOptions = (el) => {
  formData.options = formData.options.filter(opt => opt.id !== el.id)
}

const setSelected = (el) => {
  Object.keys(el).forEach(key => {
    selectedSearch[key] = el[key]
  })
  if (el.values.length) {
    values.length = 0
    el.values.forEach(item => {
      item.balls = 0
    })
    values.push(...el.values)
    formData.full_name = el.full_name
    formData.id = el.id
    formData.group_name = el.category.name
    formData.groupId = el.category.id
    formData.type = el.type.id
  }
}

onMounted(getDepartment)

const {
  v$,
  validateField,
  onSubmit,
  formData
} = useParametersCreate(props.parameters, props.closeModal, parameters, withoutCurrent, emit)

watch(props.selectedParameter, () => {
  if (props.selectedParameter.action === 'change') {
    store.commit('parameter/setIsEdit', true)
    Object.keys(formData).forEach(key => {
      formData[key] = props.selectedParameter[key]
    })
    formData.isEdit = true
    const items = props.parameters.filter(p => p.full_name !== formData.full_name)
    withoutCurrent.push(...items)
  }
}, { deep: true, immediate: true })

useVariablesSearch()

</script>

<style scoped>
.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 630px;
  width: 100%;
}
</style>

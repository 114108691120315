<template>
  <div class="tooltip">
    <button class="tooltip__bth btn-reset" type="button">
      <VIcon
        icon-name="Tooltip"
        class="tooltip__icon"
        width="22"
        height="22"
      />
    </button>

    <span class="tooltip__popup">
      {{ text }}
    </span>
  </div>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'

export default {
  name: 'VTooltip',
  components: { VIcon },
  props: {
    text: {
      type: [String, Number],
      default: 'Текст подсказки'
    }
  }
}
</script>

import { computed, onMounted, reactive, watch } from 'vue'
import { manualMemoization } from '@/utils/memoization/manualMemoization'
import { CONDITIONS_DELIVERY, CONDITIONS_PAYMENT, CURRENCY } from '@/utils/manual/manualList'
import { getIndexByFullName, spliceBy } from '@/utils/utils'
import { LOT_CRITERION } from '@/utils/lot/const'

export function useLotTable (data, isEdit, isInfo, isBidder) {
  const formData = reactive(isInfo ? {} : data)

  function renderData (objData) {
    Object.keys(objData).forEach(key => {
      formData[key] = objData[key]
    })
  }

  if (isInfo) {
    watch(data, () => {
      renderData(data.value)
    })
  }

  const payment = reactive([])
  const delivery = reactive([])
  const valutes = reactive([])

  onMounted(async () => {
    const vRes = await manualMemoization(true, CURRENCY)
    appropriation(valutes, vRes)
    const pRes = await manualMemoization(true, CONDITIONS_PAYMENT)
    appropriation(payment, pRes)
    const dRes = await manualMemoization(true, CONDITIONS_DELIVERY)
    appropriation(delivery, dRes)
  })

  const appropriation = (manual, res) => {
    if (res.length) {
      res.forEach((item, idx) => {
        item.full_name = item.name
        if (idx === 0) {
          item.balls = 100
        } else {
          item.balls = 0
        }
      })
      manual.push(...res)
    }
  }

  const head = [
    {
      name: 'Наименование параметра',
      value: 'full_name'
    },
    {
      name: 'Группа критерия',
      value: 'group_name'
    },
    {
      name: 'В/К параметра',
      value: 'coef',
      decorator: {
        name: 'input',
        size: 'small',
        type: 'number',
        isRange: true,
        range: {
          size: {
            min: 0,
            max: 1000,
            pos: [0, 1, 2]
          }
        },
        maxLength: 4
      },
      data: formData
    },
    {
      name: 'Баллы',
      value: 'balls',
      decorator: {
        name: 'modal'
      }
    }
  ]

  const headBidder = [
    {
      name: 'Наименование параметра',
      value: 'full_name'
    },
    {
      name: 'Группа критерия',
      value: 'group_name'
    },
    {
      name: 'В/К параметра',
      value: 'coef'
    },
    {
      name: 'Баллы',
      value: 'balls',
      decorator: {
        name: 'modal',
        isBidder: true
      }
    }
  ]

  const rows = reactive([])

  const getPaymentCriterion = computed(() => formData.is_criterion_payment)
  const getDeliveryCriterion = computed(() => formData.is_criterion_delivery)
  const getValutaCriterion = computed(() => formData.is_criterion_valute)
  const getDelayMaxCriterion = computed(() => formData.is_criterion_deley_max)

  watch(getPaymentCriterion, bool => {
    changeCriterion(bool, createConditionPayment, LOT_CRITERION.payment)
  })
  watch(getDeliveryCriterion, bool => {
    changeCriterion(bool, createConditionDelivery, LOT_CRITERION.delivery)
  })
  watch(getValutaCriterion, bool => {
    changeCriterion(bool, createValuta, LOT_CRITERION.valuta)
  })
  watch(getDelayMaxCriterion, bool => {
    changeCriterion(bool, createMaxDelay, LOT_CRITERION.delayMax)
  })

  const changeCriterion = (bool, fn, name) => {
    if (isBidder) return

    if (bool) {
      const item = fn()

      const criterion = formData.table_criterion.find(item => item.full_name === name)
      if (!criterion) {
        formData.table_criterion.push(item)

        if (!formData.positions?.length) return
        for (const position of formData.positions) {
          position.parameters.push(item)
        }
      }
    } else {
      if (isInfo) return

      spliceBy(name, formData.table_criterion, getIndexByFullName)

      if (!formData.positions?.length) return
      for (const position of formData.positions) {
        spliceBy(name, position.parameters, getIndexByFullName)
      }
    }
  }

  const createItemTable = (fullName, options = [], type = 2) => {
    return {
      full_name: fullName,
      group_name: 'Стоимостные',
      coef: 1,
      groupId: 1,
      is_criterion: true,
      hide: true,
      calculate: true,
      id: Math.random(),
      type,
      options
    }
  }

  const defaultBalls = (name) => [{ balls: 1, name, id: Math.random() }]

  const createConditionPayment = () => {
    return createItemTable(LOT_CRITERION.payment, payment)
  }

  const createConditionDelivery = () => {
    return createItemTable(LOT_CRITERION.delivery, delivery)
  }

  const createValuta = () => {
    return createItemTable(LOT_CRITERION.valuta, valutes)
  }

  const createMaxDelay = () => {
    return createItemTable(LOT_CRITERION.delayMax, defaultBalls(formData.deley_max), 1)
  }

  return {
    headBidder,
    head,
    rows
  }
}

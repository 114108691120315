import { Alert } from '@/extension/Alert/Alert'
import Writer from '@/modules/writer/Writer'
import { interfacePosition } from '@/modules/writer/types'
import { DefaulterNomenclatur } from '@/modules/defaulter/DefaulterNomenclatur'

export function positionLotCreate (formData) {
  const writer = new Writer(interfacePosition)
  async function addPosition (item) {
    const candidate = formData.positions.find(pos => pos.id === item.id)
    if (candidate) {
      await Alert.show('error', 'Данная позиция у вас уже добавлена')
      return
    }
    writer.checkCorrect(item)
    item.parameters.push(DefaulterNomenclatur.createPrice(item))
    formData.positions.push(item)
  }

  const deletePosition = (positionsId) => {
    formData.positions = formData.positions.filter(pos => pos.id !== positionsId)
  }

  return {
    addPosition,
    deletePosition
  }
}

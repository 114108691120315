import { useForm } from '@/use/Base/useForm'
import { computed, inject, reactive, watch } from 'vue'
import { FormHandler } from '@/utils/use/formHandler'
import { requiredValid } from '@/utils/validator/fields'
import { deepClone } from '@/utils/utils'
import { helpers } from '@vuelidate/validators'
import { PARAMETERS_CONTROLLER } from '@/utils/consts'

export function useParametersCreate (propsParameters, closeModal, parameters, withoutCurrent, emit) {
  const parametersController = inject(PARAMETERS_CONTROLLER)

  const formData = reactive({
    action: '',
    state: '',
    full_name: '',
    is_criterion: false,
    department_id: null,
    options: reactive([]),
    isEdit: false,
    group: null,
    coef: 1,
    is_selected: null
  })

  const getFullName = computed(() => {
    return formData.full_name
  })

  watch(getFullName, async () => {
    const response = await parametersController.searchParameter(formData.full_name)
    parameters.length = 0
    if (response.length) {
      response.forEach(item => {
        item.id = Math.random()
      })
      parameters.push(...response)
    }
  })

  const getWithoutCurrent = computed(() => {
    return withoutCurrent
  })

  const rules = {
    is_criterion: requiredValid,
    department_id: requiredValid,
    options: requiredValid,
    full_name: {
      ...requiredValid,
      unique: helpers.withMessage('Такое значение уже существует', (val) => {
        const isAvailable = (array) => {
          return !array.filter(parameter => parameter.full_name.toLowerCase() === val.toLowerCase()).length
        }
        if (formData.isEdit) {
          return !getWithoutCurrent.value.filter(parameter => parameter.full_name.toLowerCase() === val.toLowerCase()).length
        }
        return isAvailable(propsParameters)
      })
    }
  }

  const formAction = () => {
    if (formData.isEdit) {
      let parameter = null
      propsParameters.forEach(par => {
        if (par.full_name === formData.full_name) {
          Object.keys(formData).forEach(key => {
            par[key] = formData[key]
          })

          parameter = par
        }
      })
      emit('change', parameter)
    } else {
      const data = deepClone(formData)
      data.state = 'create'
      data.action = 'create'
      propsParameters.push(data)
      emit('add', data)
    }
    formData.isEdit = false
    closeModal()
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors
  }
}

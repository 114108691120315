<template>
  <div :class="['form__control mb-10', classForm]">
    <div v-if="!mod" :class="[isCenter ? 'm-auto' : '']">
      <VRadio
        v-for="(elem, i) in elements"
        :key="i + 1"
        :disabled="disabled"
        :value="elem"
        @selectElement="selectElement"
        :checked-element="checkedElement"
        :class-radio="classRadio"
      />
    </div>
    <div v-else>
      <VRadioModified
        v-for="(elem, i) in elements"
        :key="i + elem"
        :value="elem"
        @selectElement="selectElement"
        :checked-element="checkedElement"
      />
    </div>
    <div v-if="errors?.length">
      <small class="error-small">{{ errors[0].$message }}</small>
    </div>
    <small v-if="description && !errors?.length" class="description">{{ description }}</small>
  </div>
</template>

<script>
import VRadio from '@/components/ui/form/Radio/VRadio'
import { computed, ref, watch } from 'vue'
import { fieldProps } from '@/mixin/props'
import VRadioModified from './VRadioModified'

export default {
  props: {
    elements: {
      type: [Array, String],
      required: true
    },
    id: {
      type: String,
      required: false
    },
    mod: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: Number
    },
    classRadio: {
      type: String,
      required: false
    },
    isCenter: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mixins: [fieldProps],
  setup (props, { emit }) {
    const checkedElement = ref(props.modelValue ?? null)

    const getModelValue = computed(() => {
      return props.modelValue
    })

    watch(getModelValue, () => {
      checkedElement.value = props.modelValue
    })

    const selectElement = (value) => {
      checkedElement.value = value
      emit('update:modelValue', checkedElement.value)
    }

    return {
      selectElement,
      checkedElement
    }
  },
  components: {
    VRadio,
    VRadioModified
  }
}
</script>

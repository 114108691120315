<script setup>
import VLotMainParameters from '@/components/views/Lot/Create/VLotMainParameters'
import VLotParameters from '@/components/views/Lot/Create/VLotParameters'
import VLotPositionList from '@/components/list/PositionList/VLotPositionList'
import VButtonLoading from '@/components/ui/buttons/VButtonLoading'
import VIcon from '@/components/ui/icon/VIcon'
import VSearch from '@/components/ui/form/Search/VSearch'
import RadioList from '@/components/ui/form/Radio/RadioList'
import VModal from '@/components/ui/modal/VModal'
import useVModal from '@/use/Base/useVModal'
import { goBack } from '@/utils/goRoute'
import { useLotCreate } from '@/use/Form/Lot/Create/useLotCreate'
import { defineProps, reactive, ref, computed, watch, onBeforeMount, onMounted, inject, watchEffect } from 'vue'
import { Alert } from '@/extension/Alert/Alert'
import { useLotTable } from '@/use/Form/Lot/Create/useLotTable'
import { manualMemoization } from '@/utils/memoization/manualMemoization'
import { PROCEDURE_TYPE } from '@/utils/manual/manualList'
import { NOMENCLATUR_CONTROLLER } from '@/utils/consts'
import { useSetPattern } from '@/use/Base/useSetPattern'
import { useRoute } from 'vue-router'

const props = defineProps({
  isNotTemplate: {
    type: Boolean,
    required: false,
    default: false
  },
  pattern: {
    type: Object,
    required: false
  },
  patternId: {
    type: [Number, null],
    required: false,
    default: null
  }
})

const nomenclaturController = inject(NOMENCLATUR_CONTROLLER)
const route = useRoute()
const typeTP = reactive([])
const input = ref('')
const variables = reactive([])
const getInput = computed(() => input.value)

watch(getInput, async () => {
  try {
    variables.length = 0
    const data = await nomenclaturController.getNomenclature(getInput.value)
    if (data.length) {
      variables.push(...data)
    }
  } catch (e) {
    await Alert.show('error', e.response.data.message)
  }
})

onBeforeMount(async () => {
  typeTP.push(...await manualMemoization(true, PROCEDURE_TYPE))
})

const { showVModal, closeVModal, openVModal } = useVModal()
onMounted(() => {
  if (props.isNotTemplate) {
    openVModal()
  }
})

const {
  formData,
  v$,
  validateField,
  serverErrors,
  addPosition,
  deletePosition,
  onSubmit,
  isSend,
  createPatternLot,
  updatePatternLot,
  isEdit,
  minCriterionDisabled,
  maxCriterionDisabled
} = useLotCreate({ pattern: props.pattern, isEdit: !!route.params.id })

useLotTable(formData, isEdit)

watchEffect(() => {
  formData.positions.reduce(
    (accumulator, currentValue) => accumulator + +currentValue.price,
    0
  )
})

const { getPatterns } = useSetPattern()

async function savePatternLotHandler () {
  if (props.patternId) {
    await updatePatternLot(props.patternId)
    return getPatterns()
  }
  return createPatternLot()
}
</script>

<template>
  <div class="create__lot">
    <div class="block__subtitle mb-40 mt-60">
      {{ typeTP.find(tp => +tp.id === +formData.procedure_type_id)?.name }}
    </div>

    <div @submit.prevent class="form">
      <div class="form__inner">
        <VLotMainParameters
          :form-data="formData"
          :validate-field="validateField"
          :v$="v$"
          :server-errors="serverErrors"
        />

        <VLotParameters
          :form-data="formData"
          :validate-field="validateField"
          :v$="v$"
          :server-errors="serverErrors"
          :minCriterionDisabled="minCriterionDisabled"
          :maxCriterionDisabled="maxCriterionDisabled"
        />

        <div class="block__subtitle mb-40 mt-40 between">
          Позиции
          <div class="form__subtitle mb-40">
            Сумма позиций:
            {{ formData.positions.reduce(
            (accumulator, currentValue) => accumulator + +currentValue.price,
            0
          ) || 0 }}
          </div>
        </div>

        <div class="max-w-670">
          <VSearch
            name="search"
            placeholder="Поиск"
            class-input="input-border-all input-padding-25 input--bg-white input-height-48 input-search"
            v-model="input"
            is-variables
            variables-key="name"
            :variables="variables"
            @add="addPosition"
          />
        </div>

        <VLotPositionList
          :positions="formData.positions"
          :lot-criterion="formData.table_criterion"
          @delete="deletePosition"
        />

        <div class="flex gap-10">
          <VButtonLoading
            color="green"
            :is-errors="v$.$error"
            :is-disabled="isSend"
            @click="onSubmit"
          >
            Сохранить
          </VButtonLoading>

          <VButtonLoading
            v-if="patternId || !$route.fullPath.includes('layout')"
            class="btn-long btn-bg-transparent-icon"
            color="transparent"
            @click="savePatternLotHandler"
          >
            <VIcon
              icon-name="SaveTemplate"
              :width="24"
              :height="30"
              class="mr-10"
            />
            Сохранить шаблону
          </VButtonLoading>

          <button
            type="button"
            class="btn btn-primary btn-bg-transparent"
            @click="goBack"
          >
            Отмена
          </button>
        </div>
      </div>
    </div>
  </div>

  <teleport to="body">
    <VModal
      v-if="showVModal && isNotTemplate"
      @close="closeVModal"
      max-width-class="width-820"
    >
      <h4 class="modal__title mb-40">Тип торговой процедуры</h4>

      <div>
        <RadioList
          name="divisibility"
          v-model="formData.procedure_type_id"
          :elements="typeTP"
          :mod="false"
          class-form="mt-40 mb-20"
          class-radio="bg-white"
        />
      </div>

      <div class="modal__buttons">
        <button type="button" class="btn btn-primary btn-bg-green" @click="closeVModal">Далее</button>
      </div>

    </VModal>
  </teleport>
</template>

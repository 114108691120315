import {
  checkDefaultValueOptionCandidate,
  checkTypeOptionCandidate,
  isThereKeyInCandidate
} from './function.writer'

export default class Writer {
  constructor(type) {
    this.element = type
  }

  // check length keys object and correct default state
  checkCorrect(candidate, type = null) {
    const option = {
      candidate,
      element: type || this.element,
      key: null
    }

    Object.keys(option.element).forEach(key => {
      option.key = key
      if (!isThereKeyInCandidate(option)) {
        return this.integrateDefaultValueByKey(option)
      }

      if (!checkDefaultValueOptionCandidate(option)) {
        if (!checkTypeOptionCandidate(option)) {
          return this.integrateDefaultValueByKey(option)
        }
      }
    })
  }

  // integrate default value in candidate object
  integrateDefaultValueByKey({ candidate, key, element }) {
    if (Array.isArray(candidate[key])) {
      candidate[key].forEach(item => {
        this.checkCorrect(item, element[key].typeItem)
      })
      return
    }

    if (element[key].equal) {
      candidate[key] = candidate[element[key].equal]
    } else {
      candidate[key] = element[key].default
    }
  }
}

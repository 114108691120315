import { provide, watch, computed, ref } from 'vue'
import { useForm } from '@/use/Base/useForm'
import { FormHandler } from '@/utils/use/formHandler'
import { useGetLot } from '@/use/Base/useGetLot'
import { rulesLotCreate } from '@/use/Form/Lot/Create/rules.lotCreate'
import { positionLotCreate } from '@/use/Form/Lot/Create/position.lotCreate'
import { formDataLotCreate } from '@/use/Form/Lot/Create/formData.lotCreate'
import { actionLotCreate } from '@/use/Form/Lot/Create/action.lotCreate'
import { Observer } from '@/core/Observer'
import { criterionDisabled, setLotPositionsSum } from '@/use/Form/Lot/Create/lot.functions'

export function useLotCreate ({ pattern = null, isEditStatus = false }) {
  const isEdit = ref(isEditStatus)
  const formData = formDataLotCreate()
  const rules = rulesLotCreate(formData, isEdit)

  const observer = new Observer()

  provide('observer', observer)
  observer.subscribe(() => setLotPositionsSum(formData))

  const { maxCriterionDisabled, minCriterionDisabled } = criterionDisabled(formData)

  const getIsCriterionMaxDelay = computed(() => formData.is_criterion_deley_max)
  const changeCriterionPayment = (val) => {
    if (val) formData.is_criterion_payment = false
  }
  watch(getIsCriterionMaxDelay, changeCriterionPayment)

  const getIsCriterionPayment = computed(() => formData.is_criterion_payment)
  const changeCriterionMaxDelay = (val) => {
    if (val) formData.is_criterion_deley_max = false
  }
  watch(getIsCriterionPayment, changeCriterionMaxDelay)

  useGetLot(isEdit, formData, false, pattern)
  const { addPosition, deletePosition } = positionLotCreate(formData)
  const { createPatternLot, updatePatternLot, formAfterAction, formAction, isSendLayout } = actionLotCreate(formData, isEdit)
  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors, formAfterAction, false, true),
    serverErrors: form.serverErrors,
    deletePosition,
    createPatternLot,
    updatePatternLot,
    isSendLayout,
    addPosition,
    isEdit,
    minCriterionDisabled,
    maxCriterionDisabled
  }
}

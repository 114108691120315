<template>
  <div class="form__flex form__flex-start">
    <div class="form__left">

      <VInput
        name="name"
        :id="setFieldId('name')"
        v-model="formData.name"
        :errors="v$.name.$errors"
        :server-errors="serverErrors.value?.name"
        @blur="validateField"
        :disabled="isBidder"
        @input="validateField"
        :class-input="['input input-long input-placeholder-black input-border-bottom']"
        :maxLength="250"
        placeholder="Наименование товара/услуги"
      />

      <VTextarea
        v-if="isBidder"
        name="participant_note"
        id="participant_note"
        v-model="formData.participant_note"
        label="Примечание поставщика"
        text-area-class="textarea-bg-transparent textarea-border-gray textarea-height-150 textarea-n-resize mb-40"
      />

      <VInput
        name="price"
        :id="setFieldId('price')"
        type="number"
        v-model="formData.price"
        :errors="v$.price.$errors"
        :server-errors="serverErrors.value?.price"
        :max="100"
        :class-form="[isBidder ? '' : ' mr-20']"
        :class-input="['input input-long input-placeholder-black input-border-bottom']"
        :placeholder="isBidder ? `Ваша цена` : 'Начальная цена'"
        @input="(name) => {
          validateField(name)
          reCalc()
        }"
      />

      <div class="field_criterion">
        <VInput
          name="quantity"
          :id="setFieldId('quantity')"
          type="number"
          v-model="formData.quantity"
          :errors="v$.quantity.$errors"
          :server-errors="serverErrors.value?.quantity"
          @blur="validateField"
          @input="(val) => {
            validateField(val)
            reCalc()
          }"
          :class-form="[isBidder ? '' : 'mr-20']"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="Количество"
        />

        <VCheckbox
          v-if="!isBidder"
          :class-form="['form-checkbox mb-20 small-checkbox']"
          id="is_quantity_coef"
          name="is_quantity_coef"
          class-label="label"
          :value="formData.is_quantity_coef"
          v-model="formData.is_quantity_coef"
          @blur="validateField"
          @input="validateField"
        >
          <template #default="slotProps">
            <label :for="slotProps.for" :class="slotProps.class">
              Критерий
            </label>
          </template>
        </VCheckbox>
      </div>
    </div>

    <div class="form__right">
      <VSelect
        name="measurement_id"
        :id="setFieldId('measurement_id')"
        v-model="formData.measurement_id"
        :errors="v$.measurement_id.$errors"
        :server-errors="serverErrors.value?.measurement_id"
        @blur="validateField"
        @input="validateField"
        :disabled="isBidder && !lot.analog_acceptable"
        :options="measurement"
        classes="select-border-bottom"
        class="input-long"
        placeholder="Ед. измерения"
      />

      <VInput
        name="characteristic"
        :id="setFieldId('characteristic')"
        v-model="formData.characteristic"
        :errors="v$.characteristic.$errors"
        :max-length="301"
        @blur="validateField"
        @input="validateField"
        :disabled="isBidder && !lot.analog_acceptable"
        :class-input="['input input-long input-placeholder-black input-border-bottom']"
        placeholder="Характеристика товара/услуги"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, reactive } from 'vue'

import VSelect from '@/components/ui/form/VSelect'
import VInput from '@/components/ui/form/VInput'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import { manualMemoization } from '@/utils/memoization/manualMemoization'
import { MEASUREMENT } from '@/utils/manual/manualList'
import VTextarea from '@/components/ui/form/VTextarea'
import { useStore } from 'vuex'

const props = defineProps({
  formData: {
    type: Object,
    required: true
  },
  v$: {
    type: Object,
    required: true
  },
  serverErrors: {
    type: [Object, Array],
    required: true
  },
  validateField: {
    type: Function,
    required: true
  },
  isBidder: {
    type: Boolean,
    required: false
  },
  reCalc: {
    type: Function,
    required: false
  }
})

const store = useStore()
const measurement = reactive([])
const lot = computed(() => {
  return store.getters['lot/getLot']
})

onMounted(async () => {
  measurement.length = 0
  measurement.push(...await manualMemoization(true, MEASUREMENT))
})

function setFieldId (field) {
  return `lot-position__${field}--${props.formData.id}`
}
</script>

<style scoped>
.v-lot-pos {
  display: flex;
  width: 100%;
  max-width: 630px;
  flex-direction: column;
  align-items: center;
}
</style>

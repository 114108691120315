import { Alert } from '@/extension/Alert/Alert'
import { inject, ref } from 'vue'
import {
  changeTableInfo, createPatternLotFormData, nowDetailPage,
  sendChangeLot, sendCreateLot, sendFiles,
  transformDataForSend, validationCoef,
  validSendBalls
} from '@/use/Form/Lot/Create/lot.functions'
import { deepClone } from '@/utils/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { RenderApiError } from '@/extension/Error/RenderApiError'
import { LOT_CONTROLLER } from '@/utils/consts'
import { deleteFile } from '@/http/otherApi'

export function actionLotCreate (formData, isEdit) {
  const store = useStore()
  const router = useRouter()
  const handlerErrors = new RenderApiError()

  const formAfterAction = async () => {
    if (!formData.positions?.length) {
      await Alert.show('error', 'Невозможно сохранить изменения, в лоте нет позиций')
    }
  }

  const isSendLayout = ref(false)
  const lotController = inject(LOT_CONTROLLER)

  async function createPatternLot () {
    try {
      if (!await hasPatternName()) return
      isSendLayout.value = true

      const pattern = createPatternLotFormData(formData)
      await lotController.createPatternLot(pattern)
    } finally {
      isSendLayout.value = false
    }
  }
  async function updatePatternLot (id) {
    try {
      if (!await hasPatternName()) return
      isSendLayout.value = true

      const pattern = createPatternLotFormData(formData)
      await lotController.updatePatternLot(id, pattern)
    } finally {
      isSendLayout.value = false
    }
  }
  async function hasPatternName () {
    if (!formData.name) {
      await Alert.show('error', 'Заполните имя, чтобы сохранить шаблон')
      return false
    }

    return true
  }

  const formAction = async () => {
    try {
      // валидация коэффициентов
      for (let i = 0; i < formData.positions.length; i++) {
        const position = formData.positions[i]
        const nonValidCoef = validationCoef({
          value: position.criterion_value,
          nonValue: position.criterion_non_value,
          skill: position.criterion_skill
        }, position.parameters, i)

        if (nonValidCoef) {
          return Alert.show('error', nonValidCoef)
        }
      }

      const isValidBalls = validSendBalls(formData.positions)
      if (!isValidBalls) {
        return Alert.show('error', 'Укажите баллы')
      }

      let res
      formData.category_ids = []
      formData.categories.forEach(c => formData.category_ids.push(c.id))
      changeTableInfo(formData)
      const data = deepClone(formData)
      transformDataForSend(formData, data)

      if (isEdit.value) {
        res = await sendChangeLot(formData, data, store)
      } else {
        res = await sendCreateLot(formData, data, store)
      }

      const queueDeleteFile = store.getters['files/getQueueForDelete']
      if (queueDeleteFile.length) await deleteFile(queueDeleteFile)

      let files
      if (formData.supportingDocumentation) {
        files = await sendFiles(formData, store)
      }
      
      store.commit('files/setFiles', files.data)
      if (nowDetailPage(router)) { store.commit('item/setOneItem', res.data) }
      await router.push({ name: 'organizer-lot-info', params: { id: store.getters['lot/getLot'].id } })
      await Alert.show('success', res.message)
    } catch (e) {
      await handlerErrors.showAlert(e)
    }
  }

  return {
    formAction,
    formAfterAction,
    createPatternLot,
    updatePatternLot,
    isSendLayout
  }
}

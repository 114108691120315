<template>
  <div
    class="accordion__item mb-20"
    :class="getIsOpen ? 'accordion__item--open' : ''"
  >
    <div class="accordion__wrap">
      <div class="accordion__header accordion__header--with-form">
        <div
          class="accordion__head  accordion__head--position"
          :class="[getIsOpen ? 'mb-40' : '']"
        >
          <span :class="['accordion__count accordion__count--with-form', classTitle]">Позиция №{{ count }}</span>

          <div class="btns">
            <button
              v-if="!isBidder"
              class="btn accordion__btn accordion__btn--delete"
              @click="() => {
                deletePosition()
                reCalculation()
              }"
            >
              <VIcon icon-name="AccordionDelete"/>
            </button>
            <button
              class="btn accordion__btn"
              @click="toggleAccordion"
            >
              <VIcon class="accordion__btn-icon" width="15" height="9" icon-name="AccordionArrow"/>
            </button>
          </div>
        </div>
      </div>

      <transition name="accordion-animation">
        <div v-if="getIsOpen">
          <VLotPositionCreateForm
            :is-bidder="isBidder"
            :form-data="formData"
            :v$="v$"
            :server-errors="serverErrors"
            :validate-field="validateField"
            :re-calc="reCalculation"
            @copyPosition="$emit('copyPosition')"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import VLotPositionCreateForm from '@/components/views/Lot/Position/Part/VLotPositionCreateForm'
import VIcon from '@/components/ui/icon/VIcon'
import { usePositionCreate } from '@/use/Form/Lot/Position/usePositionCreate'
import { computed } from 'vue'

export default {
  emits: ['delete', 'toggleOpen', 'copyPosition'],
  props: {
    classTitle: {
      type: String,
      required: false
    },
    count: {
      type: Number,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    isBidder: {
      type: Boolean,
      required: false
    },
    isOpen: {
      type: [String, Number],
      required: false
    },
    scrollErrorHandler: {
      type: Function,
      required: true
    }
  },
  setup (props, { emit }) {
    const getIsOpen = computed(() => {
      return props.isOpen === props.formData.id
    })

    const toggleAccordion = () => {
      emit('toggleOpen', props.formData.id)
    }

    const deletePosition = () => {
      emit('delete')
    }

    return {
      getIsOpen,
      deletePosition,
      toggleAccordion,
      ...usePositionCreate(props.formData, { scrollErrorHandler: props.scrollErrorHandler })
    }
  },
  components: {
    VLotPositionCreateForm,
    VIcon
  }
}
</script>

<style scoped>
.btns {
  height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion__btn--delete {
  position: static;
}
</style>

export function isThereKeyInCandidate({ candidate, key }) {
  return candidate[key]
}

export function checkTypeOptionCandidate({ candidate, key, element }) {
  // eslint-disable-next-line valid-typeof
  return typeof candidate[key] === String(element[key].type) && !Array.isArray(candidate[key])
}

export function checkDefaultValueOptionCandidate({ candidate, key, element }) {
  // dont check equal object or array
  return candidate[key] === element[key].default
}

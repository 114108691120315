<template>
  <div class="form__flex form__flex-start">
    <div class="form__left">
      <VInput
        name="name"
        id="name"
        v-model="formData.name"
        :maxLength="150"
        :errors="v$.name.$errors"
        :server-errors="serverErrors.value?.name"
        @blur="validateField"
        @input="validateField"
        :disabled="isBidder"
        :class-input="[
          'input input-long input-placeholder-black input-border-bottom',
        ]"
        placeholder="Название лота"
      />

      <VSelect
        name="company_id"
        id="company_id"
        :options="company"
        v-model="formData.company_id"
        :errors="v$.company_id.$errors"
        :server-errors="serverErrors.value?.company_id"
        @blur="validateField"
        @input="validateField"
        :disabled="isBidder"
        classes="select-border-bottom"
        class="input-long"
        placeholder="Компания"
      />

      <VTextarea
        name="note"
        id="note"
        v-model="formData.note"
        :server-errors="serverErrors.value?.note"
        :disabled="isBidder"
        label="Примечание организатора"
        text-area-class="textarea-bg-transparent textarea-border-gray textarea-height-150 textarea-n-resize mb-40"
      />

      <VInput
        name="order_number"
        id="order_number"
        v-model="formData.order_number"
        :disabled="isBidder"
        :class-input="[
          'input input-long input-placeholder-black input-border-bottom',
        ]"
        placeholder="№ заказа поставщика"
      />

      <VInput
        name="responsible_full_name"
        id="responsible_full_name"
        v-model="formData.responsible_full_name"
        :errors="v$.responsible_full_name.$errors"
        :server-errors="serverErrors.value?.responsible_full_name"
        @blur="validateField"
        @input="validateField"
        :disabled="isBidder"
        :class-input="[
          'input input-long input-placeholder-black input-border-bottom',
        ]"
        placeholder="ФИО ответственного"
      />

      <transition name="slide-fade">
        <VDatePicker
          v-if="!formData.isPublishedBeforeSave && !isBidder"
          name="published_at"
          id="published_at"
          v-model="formData.published_at"
          :errors="v$.published_at.$errors"
          :server-errors="serverErrors.value?.published_at"
          @blur="validateField"
          @input="validateField"
          class-form="mb-20"
          placeholder="Дата публикации лота"
        />
      </transition>
    </div>
    <div class="form__right">
      <VPhone
        type="phone"
        id="responsible_phone"
        name="responsible_phone"
        placeholder="Номер телефона"
        mask-type
        v-model="formData.responsible_phone"
        :errors="v$.responsible_phone.$errors"
        :server-errors="serverErrors.value?.responsible_phone"
        @blur="validateField"
        @input="validateField"
        :disabled="isBidder"
        :class-input="['input input-long input-placeholder-black']"
      />

      <VCheckbox
        v-if="!isBidder"
        id="analog_acceptable"
        name="analog_acceptable"
        :is-switch="true"
        :checked="formData.analog_acceptable"
        class-label="bg-white"
        v-model="formData.analog_acceptable"
        :errors="v$.analog_acceptable.$errors"
        :disabled="isBidder"
        :server-errors="serverErrors.value?.analog_acceptable"
      >
        <template #default="slotProps">
          <label :for="slotProps.for" :class="slotProps.class">
            Допустимость аналогов
          </label>
        </template>
      </VCheckbox>

      <RadioList
        name="divisibility"
        v-model="formData.divisibility"
        :elements="divisibility"
        :server-errors="serverErrors.value?.divisibility"
        @blur="validateField"
        @input="validateField"
        :disabled="isBidder"
        :is-center="false"
        :mod="false"
        class-form="mt-40 mb-20"
        class-radio="bg-white"
      />
    </div>

    <VCheckbox
      v-if="!isBidder"
      id="isPublishedBeforeSave"
      name="isPublishedBeforeSave"
      class-form="form-checkbox"
      :is-switch="true"
      :value="formData.isPublishedBeforeSave"
      class-label="bg-white"
      v-model="formData.isPublishedBeforeSave"
      :server-errors="serverErrors.value?.isPublishedBeforeSave"
    >
      <template #default="slotProps">
        <label :for="slotProps.for" :class="slotProps.class">
          Опубликовать лот сразу после сохранения
        </label>
      </template>
    </VCheckbox>
  </div>
</template>

<script setup>
  import { defineExpose, defineProps, inject, onMounted, reactive } from 'vue'

  import VSelect from '@/components/ui/form/VSelect'
  import VTextarea from '@/components/ui/form/VTextarea'
  import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
  import RadioList from '@/components/ui/form/Radio/RadioList'
  import VInput from '@/components/ui/form/VInput'
  import VDatePicker from '@/components/ui/form/VDatePicker'
  import VPhone from '@/components/ui/form/VMask'

  import { divisibility } from '@/utils/lot/list'
  import { COMPANY } from '@/utils/manual/manualList'
  import { manualMemoization } from '@/utils/memoization/manualMemoization'

  const formData = inject('formData')
  const v$ = inject('v$')
  const serverErrors = inject('serverErrors')
  const validateField = inject('validateField')
  const company = reactive([])
  defineProps({
    isBidder: {
      type: Boolean,
      required: false,
    },
  })

  onMounted(async () => {
    company.push(...await manualMemoization(true, COMPANY))
  })

  defineExpose({
    divisibility,
    formData,
    v$,
    validateField,
    serverErrors,
  })
</script>

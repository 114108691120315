<template>
  <div class="checkbox-modified-container">
    <div class="checkbox-modified">
      <input type="radio" :id="value" class="checkbox-modified-input" @click="selectElement" :value="value" :checked="checkedElement === value" />
      <label :for="value" class="checkbox-modified-label"></label>
    </div>
    <span>{{ value }}</span>
  </div>
</template>

<script>
export default {
  emits: ['selectElement'],
  props: {
    value: {
      type: String,
      required: true
    },
    checkedElement: {
      type: [Array, String]
    },
    disabledElement: {
      type: [Array, String]
    },
    defaultElement: {
      type: String
    }
  },
  setup (props, { emit }) {
    const selectElement = () => {
      emit('selectElement', props.value)
    }

    return {
      selectElement
    }
  }
}
</script>

<style  lang="scss">
  @import "../../../../styles/variables";

//.checkbox-modified-container {
//  display: flex;
//
//  font-size: 14px;
//
//& > div {
//    margin-right: 10px;
//  }
//}
//
//.checkbox-modified-label {
//  position: relative;
//  display: block;
//  height: 30px;
//  width: 50px;
//  background: transparent;
//  border-radius: 100px;
//  border: 1px solid $color-primary;
//  cursor: pointer;
//  transition: all 300ms ease;
//
//&:after {
//   position: absolute;
//   left: 2px;
//   top: 2px;
//   display: block;
//   width: 24px;
//   height: 24px;
//   border-radius: 100px;
//   background: $color-gray;
//   box-shadow: 0 3px 3px rgba(0,0,0,0.05);
//   content: '';
//   transition: all 300ms ease;
// }
//&:active:after {
//   transform: scale(1.15, 0.85);
// }
//}
//
//.checkbox-modified-input {
//  display: none;
//  &:checked ~ label {
//    &:after {
//       left: 20px;
//       background: $color-primary;
//     }
//  }
//  &:disabled ~ label {
//    background: $color-light-gray;
//    border-color: $color-gray;
//   pointer-events: none;
//  &:after {
//    background: #fff;
//    border: 1px solid $color-light-gray;
//   }
//  }
//}
</style>
